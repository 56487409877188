import { Controller, useFormContext } from 'react-hook-form'

import { Select as EdsSelect } from '@nike/eds'

import { SelectProps } from './types'

type Props = SelectProps & {
  name: string
  options: {
    label: string
    value: string
  }[]
  required?: boolean
  isRequired?: boolean
  isMulti?: boolean
  isDisabled?: boolean
}

export function Select({ name, options, ...props }: Props) {
  const { control } = useFormContext()

  return (
    <div className='eds-spacing--my-12'>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <EdsSelect
            options={options}
            {...field}
            {...props}
            hasErrors={!!error}
            errorMessage={error?.message}
          />
        )}
      />
    </div>
  )
}
