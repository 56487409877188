// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorBoundary__errorWrapper--Ouerv .ErrorBoundary__text--X2yYL{font:var(--eds-type-body-4)}.ErrorBoundary__errorWrapper--Ouerv .ErrorBoundary__swoosh--sxpjr{color:var(--eds-color-brand-orange);width:50px;height:50px}.ErrorBoundary__errorWrapper--Ouerv .ErrorBoundary__swoosh--sxpjr *{width:100%;height:100%}.ErrorBoundary__errorWrapper--Ouerv.ErrorBoundary__fullPage--XWydu{text-align:center;padding-top:20%}.ErrorBoundary__errorWrapper--Ouerv.ErrorBoundary__fullPage--XWydu .ErrorBoundary__heading--Cfr_A{font:var(--eds-type-title-3);margin-bottom:20px}.ErrorBoundary__errorWrapper--Ouerv.ErrorBoundary__fullPage--XWydu .ErrorBoundary__text--X2yYL{font:var(--eds-type-body-1)}.ErrorBoundary__errorWrapper--Ouerv.ErrorBoundary__fullPage--XWydu .ErrorBoundary__swoosh--sxpjr{width:100px;height:100px}`, "",{"version":3,"sources":["webpack://./../../components/error-boundary/ErrorBoundary.styl"],"names":[],"mappings":"AACE,gEACE,2BAAK,CAEP,kEACE,mCAAM,CACN,UAAM,CACN,WAAO,CAET,oEACE,UAAQ,CACR,WAAQ,CAEV,mEACE,iBAAW,CACX,eAAY,CAEZ,kGACE,4BAAK,CACL,kBAAc,CAEhB,+FACE,2BAAK,CAEP,iGACE,WAAM,CACN,YAAO","sourcesContent":[".errorWrapper\n  .text\n    font var(--eds-type-body-4)\n\n  .swoosh\n    color var(--eds-color-brand-orange)\n    width 50px\n    height 50px\n\n  .swoosh *\n    width:  100%\n    height: 100%\n\n  &.fullPage\n    text-align center\n    padding-top 20%\n\n    .heading\n      font var(--eds-type-title-3)\n      margin-bottom 20px\n\n    .text\n      font var(--eds-type-body-1)\n\n    .swoosh\n      width 100px\n      height 100px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorWrapper": `ErrorBoundary__errorWrapper--Ouerv`,
	"text": `ErrorBoundary__text--X2yYL`,
	"swoosh": `ErrorBoundary__swoosh--sxpjr`,
	"fullPage": `ErrorBoundary__fullPage--XWydu`,
	"heading": `ErrorBoundary__heading--Cfr_A`
};
export default ___CSS_LOADER_EXPORT___;
