import { useAtomValue } from 'jotai'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '@nike/eds'

import { DevControls } from './components/DevControls'
import { FormFooter } from './components/FormFooter'
import { FormHeader } from './components/FormHeader'
import styles from './Form.module.styl'
import { FormField } from './FormField'
import { submitUrlAtom } from './util/atoms'
import { fillTemplate, formDataToPayload, showField } from './util/form-util'
import { useSubmitForm } from './util/hooks'
import { FormDefType } from './util/schemas'

type FormGeneratorProps = {
  definition: FormDefType
  onError?: ({ error }) => void
  onSuccess?: ({ data }) => void
  showDevControls: boolean
}

export function FormGenerator({
  definition,
  onError,
  onSuccess,
  showDevControls,
}: FormGeneratorProps) {
  const submitUrl = useAtomValue(submitUrlAtom)
  const {
    handleSubmit,
    watch,
    formState: { dirtyFields, isSubmitting, isValid },
  } = useFormContext()

  const { mutate } = useSubmitForm()

  const onSubmit = useCallback(
    (data: Record<string, unknown>) => {
      let payload: unknown = formDataToPayload(definition, data)

      if (definition.payloadTemplate) {
        payload = fillTemplate(definition.payloadTemplate, payload as Record<string, unknown>)
      }
      // post payload as request params
      // @ts-expect-error payload can be a string or object
      mutate(payload, {
        onSuccess: (_, variables) => {
          onSuccess && onSuccess({ data: variables })
        },
        onError: (error) => {
          onError && onError({ error })
        },
      })
    },
    [definition, mutate, onError, onSuccess]
  )

  return (
    <>
      <div className={styles.Form}>
        <>
          <FormHeader title={definition.header?.title} text={definition.header?.text} />
          {definition.fields.map((field, index) => {
            return showField(field.showIf, watch) ? (
              <div className='eds-spacing--my-24' key={`FormFieldWrap-${field.name}-${index}`}>
                <FormField
                  field={field}
                  key={`FormField-${field.name}`}
                  isDirty={true === dirtyFields[field.name]}
                />
              </div>
            ) : null
          })}
          <FormFooter title={definition.footer?.title} text={definition.footer?.text} />
        </>

        {submitUrl ? (
          <Button
            className='eds-spacing--mt-12'
            type='submit'
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting || !isValid}
          >
            Submit
          </Button>
        ) : null}
      </div>

      {showDevControls && <DevControls definition={definition} />}
    </>
  )
}
