import { QueryClientProvider } from '@tanstack/react-query'
import { Provider as JotaiProvider, useSetAtom } from 'jotai'
import 'rc-slider/assets/index.css'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Spinner } from '@nike/eds'

import { FormGenerator } from './FormGenerator'
import { accessTokenAtom, oktaEnvAtom, submitUrlAtom } from './util/atoms'
import { getDefaultValues } from './util/form-util'
import { useFetchFormDef } from './util/hooks'
import { queryClient } from './util/queryClient'
import { FormDefType, isDefinitionInvalid } from './util/schemas'

export type FormProps = {
  // Required if config is a URL
  accessToken?: string

  // Form config object or a URL from where to fetch the form config YAML
  definition: string | object

  // Okta env ('qa' or 'prod') for form to use, e.g. SelectWaffleIron widget
  // Default: prod
  env?: string

  // Function (or component) that receives an object of fetching/parsing errors
  onError: ({ error }: { error: unknown }) => JSX.Element

  // Function called when form is submitted successfully
  onSuccess: ({ data }: { data: unknown }) => JSX.Element

  showDevControls?: boolean
}

/**
 * Sets up Jotai and react-query providers
 */
export function Form(props: FormProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <WrappedForm {...props} />
      </JotaiProvider>
    </QueryClientProvider>
  )
}

/**
 * Fetches definition if needed and sets up the react-hook-form provider.
 */
export function WrappedForm({
  accessToken,
  definition: defOrUrl,
  env,
  onError,
  onSuccess,
  showDevControls,
}: FormProps): JSX.Element {
  const setAccessToken = useSetAtom(accessTokenAtom)
  const setOktaEnv = useSetAtom(oktaEnvAtom)
  const setSubmitUrl = useSetAtom(submitUrlAtom)
  const [definition, setDefinition] = useState()
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
    delayError: 700, // give user a chance to correct accidental errors/typos
  })
  const { reset } = methods

  const { data: fetchedFormDef, error, fetchStatus, isError } = useFetchFormDef(defOrUrl)
  setAccessToken(accessToken || '')
  setOktaEnv(env || '')

  useEffect(() => {
    let def
    if (typeof defOrUrl === 'string' && defOrUrl.startsWith('https://')) {
      if (fetchStatus === 'fetching') {
        // Clear definition so any older form is no longer shown (mostly an mfe-viewer problem)
        def = ''
      } else {
        def = fetchedFormDef
      }
    } else {
      def = defOrUrl
    }

    setDefinition(def)
    setSubmitUrl(def?.metadata?.submit)
    // Use reset to set default values after initial form render
    // https://stackoverflow.com/a/64307087
    reset(getDefaultValues(def))
  }, [defOrUrl, fetchStatus, fetchedFormDef, setSubmitUrl, reset])

  if (!definition) return <Spinner />
  if (isError) return onError({ error })
  if (
    fetchStatus === 'idle' &&
    typeof defOrUrl === 'string' &&
    defOrUrl.startsWith('https://') &&
    !accessToken
  ) {
    return onError({ error: Error('Form could not be fetched: no access token provded.') })
  }

  const isInvalid = isDefinitionInvalid(definition)
  return (
    <FormProvider {...methods}>
      {isInvalid ? (
        <div>Form definition is not valid: {isInvalid}</div>
      ) : (
        <FormGenerator
          definition={definition as FormDefType}
          {...{ showDevControls, onError, onSuccess }}
        />
      )}
    </FormProvider>
  )
}
