import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import yaml from 'yaml'

import { accessTokenAtom, submitUrlAtom } from './atoms'

export function useFetchFormDef(formDefOrUrl: string | object, options?: FetchOptionsType) {
  const accessToken = useAtomValue(accessTokenAtom)
  return useQuery({
    enabled: Boolean(
      typeof formDefOrUrl === 'string' && formDefOrUrl.startsWith('https://') && !!accessToken
    ),
    queryKey: ['formDef', formDefOrUrl, accessToken, options],
    queryFn: () =>
      fetch(String(formDefOrUrl), {
        method: options?.method || 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...(options?.headers || {}),
        },
        ...options,
      }).then(async (response) => {
        // For now, we expect the payload to be { yaml: 'yaml-string' }
        const text = await response.text()
        return yaml.parse(text)
      }),
    refetchOnWindowFocus: false,
  })
}

export const useSubmitForm = () => {
  const accessToken = useAtomValue(accessTokenAtom)
  const url = useAtomValue(submitUrlAtom)

  return useMutation({
    mutationFn: (data: object) => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      })
    },
  })
}

type FetchOptionsType = {
  body?: string
  headers?: Record<string, string>
  method?: string
}
