import styles from './ErrorBoundary.styl'
import React from 'react'

const platformConsoleSlackLink = 'https://nikedigital.slack.com/archives/CC5FN7AMV'

type ErrorBoundaryProps = {
  fullPage?: boolean
  name?: string
}

type ErrorBoundaryState = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, message: ((error || {}) as Error).message }
  }

  componentDidCatch(error: unknown) {
    console.error(error)
    // TODO: Nark ?
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={`${styles.errorWrapper} ${this.props.fullPage ? styles.fullPage : ''}`}>
          {this.props.fullPage && (
            <h1 className={styles.heading}>Oops...something isn&apos;t quite right</h1>
          )}
          <p className={styles.text}>
            An issue occurred with this component, please refresh the page. If the error happens
            again, please contact{' '}
            <a className='eds-link' href={platformConsoleSlackLink}>
              #platformconsole
            </a>
            .
          </p>
          {this.props.fullPage && (
            <svg
              aria-hidden='true'
              className='eds-icon eds-icon--nikeswoosh sxpjr5qcyZTyJwdRR1EZ'
              focusable='false'
              viewBox='0 0 24 24'
              role='img'
              width='48'
              height='48'
              fill='none'
            >
              <path
                fill='currentColor'
                fill-rule='evenodd'
                d='M21 8.719L7.836 14.303C6.74 14.768 5.818 15 5.075 15c-.836 0-1.445-.295-1.819-.884-.485-.76-.273-1.982.559-3.272.494-.754 1.122-1.446 1.734-2.108-.144.234-1.415 2.349-.025 3.345.275.2.666.298 1.147.298.386 0 .829-.063 1.316-.19L21 8.719z'
                clip-rule='evenodd'
              ></path>
            </svg>
          )}
        </div>
      )
    }

    return this.props.children
  }
}

export const withErrorBoundary =
  (Component: (props: any) => JSX.Element, fullPage = false) =>
  (props: unknown) =>
    (
      <ErrorBoundary fullPage={fullPage}>
        <Component {...(props as {})} />
      </ErrorBoundary>
    )
