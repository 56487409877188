import { useAtomValue } from 'jotai'
import { RegisterOptions } from 'react-hook-form'

import { Text, TextGroup, Tooltip } from '@nike/eds'

import { SelectWaffleIron } from './components/SelectWaffleIron'
import { Slider } from './components/Slider'
import { Checkbox, RadioGroup, Select, TextField, Toggle } from './eds-wrapped-form-components'
import { accessTokenAtom, oktaEnvAtom } from './util/atoms'
import { toOptionArray } from './util/form-util'
import { FieldDataType, FieldDefType, FieldWidgetType } from './util/schemas'

type FormFieldProps = {
  field: FieldDefType
  isDirty: boolean
}

export function FormField({ field, isDirty }: FormFieldProps): JSX.Element {
  const { name, help, options, subtitle, type, widget } = field
  const label = getLabel(field)
  const registerOptions: RegisterOptions = getValidationRules(field) as RegisterOptions
  const commonProps = { label, id: name, name, message: help, subtitle }
  const key = `field-${name}`
  const env = useAtomValue(oktaEnvAtom)
  const token = useAtomValue(accessTokenAtom)

  switch (true) {
    case widget === 'SelectWaffleIron': {
      return env === 'prod' ? (
        <SelectWaffleIron {...commonProps} env={env} token={token} key={key} />
      ) : (
        <div>
          <TextField
            {...commonProps}
            registerOptions={registerOptions}
            key={key}
            autoComplete='off'
          />
          <Tooltip
            isDark
            bodySlot={
              <TextGroup>
                <Text>
                  The Waffle Iron dropdown widget will be shown in production environments.
                </Text>
                <Text>
                  In this context your Okta token is for QA, which it does not yet support.
                </Text>
              </TextGroup>
            }
            placement='right'
          >
            <Text font='body-3' className='eds-color--secondary'>
              Why is this not a dropdown?
            </Text>
          </Tooltip>
        </div>
      )
    }

    case !!options?.length && widget === FieldWidgetType.Radio: {
      return (
        <RadioGroup {...commonProps} options={toOptionArray(options)} valueSelected='' key={key} />
      )
    }

    case !!options?.length: {
      return (
        // @ts-expect-error - props are fine
        <Select
          {...commonProps}
          isMulti={widget === FieldWidgetType.MultipleChoice}
          options={toOptionArray(options)}
          key={key}
        />
      )
    }

    case type === FieldDataType.Boolean && widget === FieldWidgetType.Toggle: {
      const props = { id: name, label, name, checked: false }
      if (!isDirty) {
        // Initialize value (workaround for useForm's `defaultValues` not working for bools)
        props.checked = Boolean(field.default) === true
      }
      return <Toggle {...props} key={key} />
    }

    case type === FieldDataType.Boolean && widget === FieldWidgetType.Checkbox: {
      const props = { id: name, label, name, checked: false }
      if (!isDirty) {
        // Initialize value (workaround for useForm's `defaultValues` not working for bools)
        props.checked = Boolean(field.default) === true
      }
      return <Checkbox {...props} key={key} />
    }

    // Automatically use a slider if field has type number and has both min and max validation rules
    case type === FieldDataType.Number && widget === FieldWidgetType.Slider:
    case type === FieldDataType.Number &&
      widget !== FieldWidgetType.Text &&
      !!field.validate?.find((rule) => rule.min !== undefined) &&
      !!field.validate?.find((rule) => rule.max !== undefined): {
      const min = field.validate?.find((rule) => rule.min !== undefined)?.min
      const max = field.validate?.find((rule) => rule.max !== undefined)?.max

      return (
        <Slider
          key={key}
          label={label}
          message={help}
          name={name}
          min={min}
          max={max}
          {...field.widgetProps}
          testId={`slider-${name}`}
        />
      )
    }

    case type === FieldDataType.Number && widget !== FieldWidgetType.Slider:
      // Number field that does not have both min and max validation rules
      // is shown as a text field with type=number.
      return (
        <TextField
          {...commonProps}
          type='number'
          registerOptions={registerOptions}
          key={key}
          autoComplete='off'
        />
      )

    case typeof type === 'undefined':
    case type === FieldDataType.String && !options: {
      return (
        <TextField
          {...commonProps}
          registerOptions={registerOptions}
          key={key}
          autoComplete='off'
        />
      )
    }

    default:
      return <></>
  }
}

function getLabel(field: FieldDefType) {
  return field.label || field.name || ''

  // Oops, EDS adds * during validation errors, so don't add one here.
  // But keeping this here so devs know why * are not initially shown.
  // if (field.validate?.find((rule) => !!rule.required)) {
  //   label = `${label} #`
  // }
}

// Fields may specify any of the validation rules supported by react-hook-form:
// https://react-hook-form.com/docs/useform/register
function getValidationRules(field: FieldDefType): RegisterOptions {
  if (!field.validate) return {}

  const rules: RegisterOptions = {}
  field.validate.forEach((rule) => {
    const { required, min, max, minLength, maxLength, message, pattern } = rule
    switch (true) {
      case required !== undefined:
        rules.required = message || 'This field is required.'
        break
      case min !== undefined:
        rules.min = { value: min, message: message || `Must be at least ${min}.` }
        break
      case max !== undefined:
        rules.max = { value: max, message: message || `Must be at most ${max}.` }
        break
      case minLength !== undefined:
        rules.minLength = {
          value: minLength,
          message: message || `Must have a length of at least ${minLength}.`,
        }
        break
      case maxLength !== undefined:
        rules.maxLength = {
          value: maxLength,
          message: message || `Must have a length of at most ${maxLength}.`,
        }
        break
      case pattern !== undefined:
        rules.pattern = {
          value: new RegExp(pattern),
          message: message || `Must match regex pattern: ${pattern}.`,
        }
        break
      default:
    }
  })

  return rules
}
