import { Components } from 'react-markdown'

import { Link } from '@nike/eds'

export const customMarkdownComponents: Components = {
  a: (props) => (
    <Link
      target='_blank'
      rel='noopener noreferrer'
      // instead of blue text, use eds-color--default color & underline
      style={{ color: '#111111', textDecoration: 'underline' }}
      {...props}
    />
  ),
}
