import cx from 'classnames'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button } from '@nike/eds'

import { fillTemplate, formDataToPayload } from '../util/form-util'
import { FormDefType } from '../util/schemas'
import { Code } from './Code'
import styles from './DevControls.module.styl'

export function DevControls({ definition }: Props) {
  const { watch } = useFormContext()
  const [showPanel, setShowPanel] = useState(false)
  const formStateUnprepared = watch()
  const payload = formDataToPayload(definition, formStateUnprepared)
  const filledTemplate = fillTemplate(definition.payloadTemplate, payload)
  return (
    <div className='eds-spacing--mt-48'>
      <Button
        onClick={() => setShowPanel(!showPanel)}
        size='small'
        variant={showPanel ? 'primary' : 'secondary'}
      >
        Debug
      </Button>
      {showPanel && (
        <div className={cx('eds-spacing--p-16 eds-spacing--m-24', styles.Debug)}>
          <div className='eds-spacing--4'>
            {definition.payloadTemplate && (
              <div className='eds-spacing--mb-24'>
                <h4>Payload via `payloadTemplate`:</h4>
                <Code isText={typeof filledTemplate === 'string'}>{filledTemplate}</Code>
              </div>
            )}
            <div className='eds-spacing--mb-24'>
              <h4>Form State:</h4>
              <Code isText={typeof payload === 'string'}>{payload}</Code>
            </div>
            <div>
              <h4>JSON Definition:</h4>
              <Code>{definition}</Code>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

type Props = {
  definition: FormDefType
}
