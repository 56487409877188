import { forwardRef, Ref } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

import { TextField as EdsTextField } from '@nike/eds'
import { TextFieldProps } from '@nike/eds/dist/components/TextField/TextField.d'

type Props = TextFieldProps & {
  id?: string
  name: string
  registerOptions?: RegisterOptions
}

export const TextField: React.ForwardRefRenderFunction<HTMLInputElement, Props> = forwardRef(
  ({ id, name, label = '', registerOptions, ...props }, ref: Ref<HTMLInputElement>) => {
    const {
      register,
      formState: { errors },
    } = useFormContext()

    const errorMessage = errors[name]?.message || ''

    return (
      <div className='eds-spacing--my-12'>
        {/* @ts-expect-error it's okay, calm down */}
        <EdsTextField
          {...{ id, label, ref, errorMessage, hasErrors: !!errorMessage }}
          {...props}
          {...register(name, registerOptions)}
        />
      </div>
    )
  }
)
