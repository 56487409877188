import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { Toggle as EdsToggle } from '@nike/eds'
import { ToggleProps } from '@nike/eds/dist/components/Toggle/Toggle.d'

type Prop = ToggleProps & {
  name: string
  registerOptions?: RegisterOptions
}
export function Toggle({ name, registerOptions, ...props }: Prop) {
  const { control } = useFormContext()
  return (
    <div className='eds-spacing--my-24'>
      <Controller
        name={name}
        control={control}
        rules={registerOptions}
        render={({ field }) => <EdsToggle {...props} {...field} />}
      />
    </div>
  )
}
