import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import 'react-hook-form'

import { TextArea as EdsTextArea } from '@nike/eds'
import { TextAreaProps } from '@nike/eds/dist/components/TextArea/TextArea.d'

type Props = TextAreaProps & {
  id?: string
  name: string
  rows?: number
  registerOptions?: RegisterOptions
}

export const TextArea = ({ id, name, registerOptions, rows = 4, ...props }: Props): JSX.Element => {
  const { control } = useFormContext()
  return (
    <div className='eds-spacing--my-12'>
      <Controller
        name={name}
        control={control}
        rules={registerOptions}
        render={({ field, fieldState: { error } }) => {
          return (
            <EdsTextArea
              id={id}
              rows={rows}
              {...props}
              {...field}
              hasErrors={!!error}
              errorMessage={error?.message}
            />
          )
        }}
      />
    </div>
  )
}
