import { QueryCache, QueryClient } from '@tanstack/react-query'

const queryCache: QueryCache = new QueryCache({
  onSuccess: (): void => {},
  onError: (error: Error | null): void => {
    throw error
  },
  onSettled: (): void => {},
})

export const queryClient: QueryClient = new QueryClient({ queryCache })
