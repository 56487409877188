// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form-module__Form--WznG1{width:auto}`, "",{"version":3,"sources":["webpack://./src/Form.module.styl"],"names":[],"mappings":"AAAA,0BACI,UAAO","sourcesContent":[".Form\n    width: auto\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": `Form-module__Form--WznG1`
};
export default ___CSS_LOADER_EXPORT___;
