import ReactMarkdown from 'react-markdown'

import { Text } from '@nike/eds'

import { customMarkdownComponents } from '../util/markdown'
import { HeaderType } from '../util/schemas'

export function FormHeader({ title, text }: HeaderType) {
  return (
    <div className='FormHeader eds-spacing--mb-72'>
      {title && <Text font='title-3'>{title}</Text>}
      {text && (
        <div className='eds-spacing--my-16 eds-type--body-1'>
          <ReactMarkdown components={customMarkdownComponents}>{text}</ReactMarkdown>
        </div>
      )}
    </div>
  )
}
