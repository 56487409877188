export type FormDefType = {
  apiVersion: string
  kind: string
  metadata: {
    name: string
    submit?: string
  }
  header?: HeaderType
  fields: FieldDefType[]
  footer?: FooterType
  payloadTemplate?: string
}

export enum FieldDataType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  StringList = 'string-list',
}
export enum FieldWidgetType {
  Checkbox = 'checkbox',
  Choice = 'select',
  MultipleChoice = 'multi-select',
  Radio = 'radio',
  Text = 'text',
  Toggle = 'toggle',
  Slider = 'slider',
}

export type FieldDefType = {
  // Name maps to XRD property name.
  name: string

  // Human-friendly label
  label?: string

  // Defaults to 'string'
  type?: FieldDataType

  // If widget is specified, it can be one of 'select', 'radio', 'multi-select',
  // or a custom widget such as '@cx/x-product/SelectWaffleIron'.
  widget?: string
  // Key-value pairs passed to the widget component
  widgetProps?: Record<string, string>

  // Optional default value
  default?: string | number | readonly string[]

  // If options are specified, field will use 'select' widget by default; however,
  // a list of 3 or fewer options will use 'radio' widget by default.
  options?: Option[]

  // Shown above field below label using EDS 'subtitle' prop
  subtitle?: string

  // Shown below field using EDS 'message' prop
  help?: string

  // Array of field validation rules passed to react-hook-form.
  // See https://react-hook-form.com/docs/useform/register
  // Examples:
  //
  //    validate:
  //        - required: true
  //        - pattern: ^\\d{12}$
  //          message: Must be a 12 digit number.
  //        - pattern: ^[a-zA-Z0-9-]+$
  //          message: Only letters, numbers, and hyphen character allowed.
  //        - min: 1
  //        - max: 100
  validate?: ValidationRule[]

  // Multiple rules are ANDed together
  showIf?: ShowIfRule[]
}

export type ValidationRule = {
  message?: string

  // min & max of a numeric value
  min?: number
  max?: number

  // min & max length of a string value
  minLength?: number
  maxLength?: number

  required?: boolean

  /*
  pattern:
    JavaScript Regular expression for validation.
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions

    Examples:
        "^[a-zA-Z0-9-_]+$" only accept alphanumeric and - or _.
        "^a\d{1,3}$" accept a string beginning with "a" followed by 1-3 digits
  */
  pattern?: string
}

export type Option = string | { label: string; value: string }

export type FormStep = {
  name: string
}

export type ShowIfRule = {
  field: string
  hasValue: string | boolean | number
}

export type HeaderType = {
  // Plain text presented as a heading
  title?: string
  // Plain text or markdown https://commonmark.org/help/
  // presented as normal body text.
  text?: string
}

export type FooterType = {
  // Plain text presented as a heading
  title?: string
  // Plain text or markdown https://commonmark.org/help/
  // presented as normal body text.
  text?: string
}

export function isDefinitionInvalid(definition: unknown): string {
  try {
    switch (true) {
      case !definition:
      case typeof definition !== 'object':
        return 'Form definition must be an object'
      // @ts-expect-error - definition may be invalid
      case !definition.metadata || !definition.metadata?.name || !definition.metadata?.submit:
        return 'Form definition must have metadata with at least name and submit properties'
      // @ts-expect-error - definition may be invalid
      case definition.fields && !Array.isArray(definition.fields):
        return 'Form definition must have an array of fields'
      // @ts-expect-error - definition may be invalid
      case new Set(definition.fields.map((field) => field.name)).size !== definition.fields.length:
        return 'All field names must be unique'
      default:
        return ''
    }
  } catch (e) {
    return `Failed to parse form definition: ${e.message}`
  }
}
