// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Code-module__Code--pqUAy pre{background-color:var(--eds-color-background-disabled);overflow:auto;padding:8px}`, "",{"version":3,"sources":["webpack://./src/components/Code.module.styl"],"names":[],"mappings":"AACI,8BACI,qDAAiB,CACjB,aAAS,CACT,WAAQ","sourcesContent":[".Code\n    pre\n        background-color var(--eds-color-background-disabled)\n        overflow auto\n        padding 8px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Code": `Code-module__Code--pqUAy`
};
export default ___CSS_LOADER_EXPORT___;
