import decode from 'jwt-decode'
import { Controller, useFormContext } from 'react-hook-form'

import { SelectWaffleIron as XSelectWaffleIron } from '@nike/cx-x-product'

export function SelectWaffleIron({
  env,
  id,
  label,
  name,
  message,
  placeholder,
  subtitle,
  token,
}: Props) {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  if (!token || !env) return <></>

  const error = errors[name]
  const email = decode<NikeJwtToken>(token).sub

  return (
    <Controller
      control={control}
      name={name}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...rest } }) => (
        <XSelectWaffleIron
          accessToken={token}
          env={'prod'} // widget only supports prod
          errorMessage={error?.message}
          hasErrors={!!error?.message}
          id={id || name}
          label={label || 'AWS Waffle Iron Account'}
          message={message}
          placeholder={placeholder}
          username={email}
          subtitle={subtitle}
          {...rest}
        />
      )}
    />
  )
}

type Props = {
  env: string
  id?: string
  label?: string
  message?: string
  name: string
  placeholder?: string
  subtitle?: string
  token: string
}

// Here we don't care about all the props in the token
type NikeJwtToken = {
  sub: string
}
