import RCSlider, { SliderProps as RCSliderProps } from 'rc-slider'
import { Controller, useFormContext } from 'react-hook-form'

import { Label, Text } from '@nike/eds'

export default function Slider({
  label,
  message,
  name,
  testId,

  // Pass other rc-slider options via widgetProps object, for example:
  //    widgetProps:
  //      step: 5
  //      dots: true
  // See https://github.com/schrodinger/rc-slider?tab=readme-ov-file#api
  ...rest
}: SliderProps & RCSliderProps) {
  const { control, setValue } = useFormContext()

  return (
    <div data-testid={testId}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className='eds-spacing--my-24'>
            <Label font='title-6' className='eds-spacing--mb-16'>
              {label}
            </Label>
            <Text className='eds-spacing--mb-16'>Value: {field.value}</Text>
            <RCSlider
              styles={{
                track: { backgroundColor: '#000' },
                handle: { borderColor: '#ccc', boxShadow: '0 0 0 5px #00000033' },
              }}
              onChange={(value) => {
                setValue(name, value)
              }}
              value={field.value}
              {...rest}
            />
            {message && (
              <Text font='body-3' className='eds-spacing--m-8'>
                {message}
              </Text>
            )}
            {error && (
              <Text font='body-3' className='eds-spacing--m-8 eds-color--danger'>
                {error.message}
              </Text>
            )}
          </div>
        )}
      />
    </div>
  )
}

type SliderProps = {
  label: string
  message?: string
  name: string
  testId?: string
}
