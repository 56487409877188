import { FieldDefType, FormDefType, Option, ShowIfRule } from './schemas'

export function formDataToPayload(definition: FormDefType, formData: Record<string, unknown>) {
  const payload: Record<string, unknown> = {}
  definition.fields.map((field: FieldDefType) => {
    const valueOrOption = formData[field.name]
    // Convert select options { value; 'a', label: 'A' } to just their string values 'a'
    // @ts-expect-error it's okay, calm down
    const value = typeof valueOrOption === 'object' ? valueOrOption.value : valueOrOption

    // Only include fields that are shown in the form
    if (showField(field.showIf, (name: string) => formData[name])) {
      payload[field.name] = value
    }
  })

  return payload
}

export function fillTemplate(template: string, payload: Record<string, unknown>): string {
  if (!template) return ''
  let filled = template

  Object.keys(payload).forEach((name) => {
    const value = getValueOrOptionValue(payload[name])
    filled = filled.replaceAll(`{{ ${name} }}`, value)
  })
  return filled
}

function getValueOrOptionValue(valueOrOption: unknown) {
  // @ts-expect-error it's okay, calm down
  return typeof valueOrOption === 'object' ? valueOrOption.value : valueOrOption
}

export function showField(rules: ShowIfRule[] | undefined, getValue: (name: string) => unknown) {
  if (!rules?.length) return true

  return rules.every((showIfRule) => {
    const otherFieldValue = getValue(showIfRule.field)
    return otherFieldValue === showIfRule.hasValue
  })
}

export function toOptionArray(options: string[] | Option[]): SelectOption[] {
  if (!options.length) return []
  return options.map((option: string | Option) => {
    if (typeof option === 'string') {
      return { value: option, label: option }
    } else {
      return option
    }
  })
}
type SelectOption = { value: string; label: string }

export function getDefaultValues(definition: FormDefType | undefined) {
  if (!definition?.fields) return {}

  const defaults: Record<string, unknown> = {}
  definition.fields.forEach((field) => {
    const { default: defaultValue, options, name } = field
    if (defaultValue !== undefined) {
      const value = options
        ? toOptionArray(options).find((o) => o.value === defaultValue)
        : defaultValue

      // Support 2 levels of names via dot-notation
      const [first, second] = name.split('.')
      if (second) {
        if (typeof defaults[first] === 'undefined') {
          defaults[first] = { [second]: value }
        } else {
          defaults[first][second] = value
        }
      } else {
        defaults[first] = value
      }
    }
  })
  return defaults
}
