import { Controller, useFormContext } from 'react-hook-form'

import { Radio as EdsRadio, RadioGroup as EdsRadioGroup } from '@nike/eds'

import { RadioGroupProps } from './types'

type Prop = RadioGroupProps & {
  name: string
  defaultValue?: unknown | undefined
  options: {
    label: string
    value: string
    id?: string
    disabled?: boolean
  }[]
}

export function RadioGroup({ name, options, defaultValue = undefined, ...props }: Prop) {
  const { control } = useFormContext()

  return (
    <div className='eds-spacing--my-24'>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || null}
        render={({ field }) => (
          <EdsRadioGroup name={name} onChange={(e) => field.onChange(e.target.value)} {...props}>
            {options?.map((option) => (
              <EdsRadio
                key={option.value}
                label={option.label}
                value={option.value}
                id={option.value}
                disabled={option.disabled}
              />
            ))}
          </EdsRadioGroup>
        )}
      />
    </div>
  )
}
