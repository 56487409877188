import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox as EdsCheckbox } from '@nike/eds'
import { CheckboxProps } from '@nike/eds/dist/components/Checkbox/Checkbox.d'

type Props = CheckboxProps & {
  name: string
}

export function Checkbox(props: Props) {
  const { control } = useFormContext()
  return (
    <div className='eds-spacing--my-12'>
      <Controller
        control={control}
        name={props.name}
        render={({ field }) => <EdsCheckbox {...props} {...field} />}
      />
    </div>
  )
}
